import React from "react";
import styled from 'styled-components';

const OverlayTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    width: 80%;

    h1 {
        color: #fff;
        font-size: 70px;
        margin-bottom: 20px;
        text-align: center;
        font-weight: 800;
        line-height: 1.2;
    }

    p {
        font-size: 32px;
    }

    @media (max-width: 768px) {
        padding: 10px;

        h1 {
            font-size: 30px;
        }

        p {
            font-size: 24px;
        }
    }
`;

const VideoBackgroundContainer = styled.div`
    width: 100%;
    height: 90vh;
    overflow: hidden;
    position: relative;
    border-radius: 0 0 50px 50px;

    @media (max-width: 768px) {
        height: 80vh;
    }
`;

const VideoBackground = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    filter: blur(5px);
`;

const HeroOverlay = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 100px 40px;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
`;

const ButtonGroup = styled.div`
    display: flex;
    gap: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
    }
`;

const ActionButton = styled.a`
    background-color: #fff;
    color: #282828;
    padding: 15px 30px;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    text-decoration: none;
    cursor: pointer;
    border: 2px solid #fff;
    text-align: center;

    &:hover {
        background-color: #282828;
        color: #fff;
        border-color: #fff;
    }

    @media (max-width: 768px) {
        font-size: 16px;
        padding: 10px 20px;
    }
`;

export default function Hero() {
    return (
        <VideoBackgroundContainer>
            <VideoBackground autoPlay loop muted>
                <source src='https://s3.amazonaws.com/tirrell.studio-assets/hero.mp4' type="video/mp4" />
                Your browser does not support the video tag.
            </VideoBackground>
            <HeroOverlay>
                <OverlayTextContainer>
                    <h1>Professional Websites and Software Solutions</h1>
                    {/* <ActionButton onClick={() => window.location.href = '#contact'}>Learn More</ActionButton> */}
                    <ButtonGroup>
                        {/* <ActionButton href="/contact">Fill Out Our Contact Form</ActionButton> */}
                        <ActionButton href="tel:+12198097690">Give Us a Call</ActionButton>
                        <ActionButton href="mailto:tirrellstudio@gmail.com">Send Us an Email</ActionButton>
                    </ButtonGroup>
                </OverlayTextContainer>
            </HeroOverlay>
        </VideoBackgroundContainer>
    );
}