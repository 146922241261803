import React from "react";
import { Container, Navbar, Nav } from 'react-bootstrap';
import styled from 'styled-components';
import Logo from '../assets/images/logo-white.svg';

const StyledNavbar = styled(Navbar)`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center; /* Centers the content within the navbar */
    width: 95%;
    padding: 20px 0;
    margin: 20px 0;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.5);
    border: 2px solid #999999;
    max-width: 1580px;
`;

const NavbarContainer = styled(Container)`
    display: flex;
    justify-content: space-between; /* Space between logo and nav links */
    align-items: center; /* Vertical alignment */
`;

const LogoImg = styled.img`
    height: 50px;
    margin-right: 10px;
`;

export default function Header() {
    return (
        <StyledNavbar bg="dark" variant="dark" expand="lg" sticky="top">
            <NavbarContainer>
                <Navbar.Brand href="#home" className="d-flex align-items-center">
                    <LogoImg src={Logo} alt="Tirrell Studio" />
                </Navbar.Brand>
                {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link href="#services">Services</Nav.Link>
                        <Nav.Link href="#portfolio">Pricing</Nav.Link>
                        <Nav.Link href="#about">About Us</Nav.Link>
                        <Nav.Link href="#contact">Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse> */}
            </NavbarContainer>
        </StyledNavbar>
    );
}