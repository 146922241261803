import React from "react";
import styled from "styled-components";

const CallToActionSection = styled.section`
    background-color: #8cc63f;
    color: #fff;
    padding: 80px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media (max-width: 768px) {
        padding: 50px 20px;
    }
`;

const Heading = styled.h2`
    font-size: 40px;
    margin-bottom: 20px;
    font-weight: bold;
    color: #000;

    @media (max-width: 768px) {
        font-size: 28px;
    }
`;

const Subheading = styled.p`
    font-size: 20px;
    margin-bottom: 40px;
    max-width: 600px;

    @media (max-width: 768px) {
        font-size: 18px;
    }
`;

const ButtonGroup = styled.div`
    display: flex;
    gap: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
    }
`;

const ActionButton = styled.a`
    background-color: #fff;
    color: #8cc63f;
    padding: 15px 30px;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    text-decoration: none;
    cursor: pointer;
    border: 2px solid #fff;

    &:hover {
        background-color: #222529;
        color: #fff;
        border-color: #fff;
    }

    @media (max-width: 768px) {
        font-size: 16px;
        padding: 10px 20px;
    }
`;

export default function CallToAction() {
    return (
        <CallToActionSection>
            <Heading>Ready to Bring Your Vision to Life?</Heading>
            <Subheading>Contact us today so we can discuss how we can help you create the perfect website, software solution, or graphic design for your business.</Subheading>
            <ButtonGroup>
                {/* <ActionButton href="/contact">Fill Out Our Contact Form</ActionButton> */}
                <ActionButton href="tel:+12198097690">Give Us a Call</ActionButton>
                <ActionButton href="mailto:tirrellstudio@gmail.com">Send Us an Email</ActionButton>
            </ButtonGroup>
        </CallToActionSection>
    );
}
