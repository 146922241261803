import React from "react";
import styled from "styled-components";

const ServicesSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 20px;
    color: #222529;

    @media (max-width: 768px) {
        padding: 50px 20px;
    }
`;

const ServicesHeading = styled.h2`
    font-size: 48px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 40px;

    @media (max-width: 768px) {
        font-size: 32px;
    }
`;

const ServicesGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    max-width: 1200px;
    width: 100%;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 20px;
    }
`;

const ServiceCard = styled.div`
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 40px;
    text-align: center;

    h3 {
        font-size: 28px;
        margin-bottom: 20px;
        color: #8cc63f;
    }

    p {
        font-size: 18px;
        color: #555;
    }

    @media (max-width: 768px) {
        padding: 20px;

        h3 {
            font-size: 24px;
        }

        p {
            font-size: 16px;
        }
    }
`;

export default function Services() {
    return (
        <ServicesSection id="services">
            <ServicesHeading>Our Services</ServicesHeading>
            <ServicesGrid>
                <ServiceCard>
                    <h3>Website Design, Development, and Hosting</h3>
                    <p>Custom websites tailored to your brand, built for both desktop and mobile, ensuring high-quality user experiences.</p>
                </ServiceCard>
                <ServiceCard>
                    <h3>Custom Software Solutions</h3>
                    <p>Innovative software solutions built to solve your business problems, including automation, data processing, and more.</p>
                </ServiceCard>
                <ServiceCard>
                    <h3>Graphic Design</h3>
                    <p>Eye-catching logos, business cards, and marketing materials to help your brand stand out from the competition.</p>
                </ServiceCard>
            </ServicesGrid>
        </ServicesSection>
    );
}