import React from "react";

import Header from '../../components/Header';
import Hero from './components/Hero';
import Services from './components/Services';
import WhyUs from './components/WhyUs';
import Testimonials from "./components/Testimonials";
import CallToAction from "./components/CallToAction";
import Footer from '../../components/Footer';

import { PageContainer}  from '../../components/StyledComponents';

export default function Home() {
  return (
    <PageContainer>
      <Header />
      <Hero />
      <Services />
      <WhyUs />
      <Testimonials />
      <CallToAction />
      <Footer />
    </PageContainer>
  );
}