import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const testimonials = [
    {
        name: 'Isai',
        title: 'Owner, Clean Choice Pressure Washing',
        message:
            '"Tirrell Studio is amazing with building your website. They did an amazing job on my business website, making sure I was pleased with it and answered my questions and was very available in making any changes I needed done to it. I would definitely recommend anyone to them!"',
    },
    {
        name: 'Niwakv',
        title: 'Owner, Nsooki Express Braiding',
        message:
            '"He did exactly what I wanted and beyond, he is patient, passionate, and professional. I really have nothing bad to say... He delivered!"',
    },
    {
        name: 'Chase',
        title: 'Owner, Good Under Pressure Soft Washing',
        message:
            '"Tirrell Studio designed my company logo and they did a great job. I especially liked how they would often check in with me to make sure they were designing something that matched my brand and expectations. I would highly recommend working with them."',
    }
];

const swoopIn = keyframes`
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
`;

const swoopOut = keyframes`
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(-100%);
        opacity: 0;
    }
`;

const Header = styled.h2`
    position: absolute;
    top: 75px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        top: 20px;
    }
`;

const TestimonialSection = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f5f5f5;
    color: #222529;
    text-align: center;
    position: relative;
    height: 600px;
    overflow-x: hidden;
    overflow-y: hidden;

    @media (max-width: 768px) {
        padding: 100px 20px;
    }
`;

const TestimonialCard = styled.div`
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    animation: ${(props) => (props.direction === 'in' ? swoopIn : swoopOut)} 0.5s ease-in-out forwards;/
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Message = styled.p`
    font-size: 18px;
    margin-bottom: 20px;
`;

const Author = styled.h3`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
`;

const Title = styled.h4`
    font-size: 16px;
    color: #666;
`;

const Arrow = styled.button`
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    ${(props) => (props.direction === 'left' ? 'left: 20px;' : 'right: 20px;')}
    transform: translateY(-50%);
    color: #8cc63f;

    &:hover {
        color: #222;
    }
`;

export default function Testimonials() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState('in');
    const [swipeTime, setSwipeTime] = useState(0);

    const handleNext = () => {
        setDirection('out');
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
            setDirection('in');
        }, 500);
        setSwipeTime(0);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setSwipeTime((prevTime) => prevTime + 1);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (swipeTime >= 10) {
            handleNext();
        }
    }, [swipeTime]);

    return (
        <TestimonialSection>
            <Header>Testimonials</Header>
            <TestimonialCard key={currentIndex} direction={direction}>
                <Message>{testimonials[currentIndex].message}</Message>
                <Author>{testimonials[currentIndex].name}</Author>
                <Title>{testimonials[currentIndex].title}</Title>
            </TestimonialCard>
            <Arrow direction='right' onClick={handleNext}>
                {'>'}
            </Arrow>
        </TestimonialSection>
    );
}