import React from "react";
import styled from "styled-components";

const WhyUsSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px 20px;
    background-color: #222529;
    color: #fff;
    text-align: center;
    width: 100%;

    @media (max-width: 768px) {
        padding: 50px 20px;
    }
`;

const WhyUsHeading = styled.h2`
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        font-size: 28px;
    }
`;

const WhyUsText = styled.p`
    font-size: 20px;
    max-width: 800px;
    margin-bottom: 40px;

    @media (max-width: 768px) {
        font-size: 18px;
    }
`;

const ActionButton = styled.button`
    background: #8cc63f;
    color: #fff;
    border-radius: 5px;
    padding: 15px 30px;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
    border: 3px solid #8cc63f;
    text-transform: uppercase;

    &:hover {
        background: #fff;
        color: #8cc63f;
    }

    @media (max-width: 768px) {
        font-size: 18px;
    }
`;

export default function WhyUs() {
    return (
        <WhyUsSection>
            <WhyUsHeading>Why Choose Tirrell Studio?</WhyUsHeading>
            <WhyUsText>
              At Tirrell Studio, we’re always learning and evolving to stay ahead in a rapidly changing industry. Our approach is highly collaborative, using an iterative process that ensures we work closely with you to build exactly what you need. By delivering small, working versions of your project at each step, we gather your feedback and make improvements along the way, ensuring the final result meets and exceeds your expectations.
            </WhyUsText>
            {/* <ActionButton onClick={() => window.location.href = '/about'}>
                Read More About Us
            </ActionButton> */}
        </WhyUsSection>
    );
}